import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';

const AddPhotos = () => {

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (data) => {

            axios.post("https://bamchas-server.glitch.me/Photos", data)
            .then((res) => {
              if (res.data.insertedId) {
                alert("Added SuccessFully");
                reset();
                window.location.reload();
              }
            });
    }



    return (
        <div style={{marginRight: '15px'}} className="my-5 px-1">
            <h3 style={{ color: "#002147" }} className="fw-bold">
                Add New Photo
            </h3>
            <hr />
            <div className="mx-1 mt-4" style={{ paddingBottom: '70px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row text-start justify-content-center">
                        <div className="col-lg-5">
                            <label className="form-label">Image URL*</label>
                            <input
                                required
                                className="mb-4 form-control"
                                {...register("img")}
                                placeholder="Enter Image URL"
                              
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Choose Date *</label>
                            <input
                                required
                                type="date"
                                className="mb-4 form-control"
                                {...register("date")}
                                
                            />
                        </div>
                    </div>
                    <input
                        className="btn btn-outline-success"
                        value="Submit"
                        type="submit"
                    />
                </form>
            </div>
        </div>
    );
};

export default AddPhotos;