import React from 'react';

const AD = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/AD.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>Y Chandrajit</h4>
                <p>Adviser of BAMCHAS <br />

                    General Manager, Bangladesh Mukktijuddha Jadughar, Dhaka </p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{ fontFamily: 'Lexend' }} className='ms-1'>
                    Welcome to the start of your life as a college or university student! Your study at College or University is an important time of discovery, and we’re here to support you along the way.

                    We’re sure you have all sorts of questions as you get ready to join us. The information on this website is a good place to start. Be sure to click around and, if you have questions we haven’t anticipated, send us a note so that we can get you what you need.

                    As you prepare for your educational program social activities here at BAMCHAS, we encourage you to check out the various ways that you can engage in and out of the classroom. We have educated students committee and well educated Advisory Board that will help you as you explore career options learn more about how to connect what you’re learning in real world. The BAMCHAS Cultural Team is an excellent way for you to connect your interests and passions with cultural minded students. We are all working together to help you thrive here at BAMCHAS.

                    Thanks.
                </p>
            </div>
        </div>
    );
};

export default AD;