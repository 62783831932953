import React from 'react';

const ST = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/ST.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>Ahaibam Ranjit Singha</h4>
                <p>Adviser of BAMCHAS <br />
                    Senior Teacher, Choto Dhamai High School Juri Kulaura </p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{ fontFamily: 'Lexend' }} className='ms-1'>
                To build society and nation the role of students is immense. The history of Bangladesh is a burning example. Society is not holding firm. New dramatic motion is driving constantly. And due to the motion some unexpected incidents happen. If it is not managed in time situation become worse. Superannuation always tries to hold the old whereas youth wants to welcome the change in alienation. It makes an internal dispute and progress comes down. Manipuri society’s present situation is the same. But the question is that who is going to pull the society up? BAMCHAS-the student organization is the answer. Beyond of factions and demarcation only students can bring up the society. It’s true that the calculation of gain and loss depends on time. According to its birth BAMCHAS didn’t passed a longer time so its gain should not be underestimated. Actually the “Primary Scholarship Program” and the “Educational Equipment to the insolvent” are the steps are praised by the bollocks of time. Although its journey was slowed down for a while but with a new hope and enthusiasm the journey of BAMCHAS resumed again. BAMCHAS would be proud of Manipuri society if the volunteers can be assembled under the organizations flag.
                </p>
            </div>
        </div>
    );
};

export default ST;