import React from 'react';

const Committee = () => {
    return (
        <div className='mx-lg-5 px-lg-5'>
            <img className='w-75' src="https://i.ibb.co/8rnjqm2/Committee.jpg" alt=""/>
        </div>
    );
};

export default Committee;