import Button from "@restart/ui/esm/Button";
import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./nav.css";


const ResponsiveNav = () => {
  const { logout, user, admin } = useAuth();

  return (
    <div style={{ backgroundColor: "#265c6e" }}>
      <Navbar
        style={{ padding: "0px"}}
        collapseOnSelect
        variant="dark"
        expand="lg">
        <Navbar.Brand className="ms-1">
          <img style={{ width: '350px' }} src="https://i.ibb.co/Zd8gDh6/BAMCHASMUNIPURICHATROSOMITI-copy.png" alt="" />
        </Navbar.Brand>

        <Navbar.Toggle className="ms-1 mb-1" aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav" className="mx-0 justify-content-end pe-2 text-light">
          <Nav className="text-start">
            <Nav.Link className="link me-lg-4" as={Link} to="/Home">
              Home
            </Nav.Link>
            <Nav.Link className="link me-lg-4" as={Link} to="/News">
              News
            </Nav.Link>
            <Nav.Link className="link me-lg-4" as={Link} to="/Photos">
              Gallery
            </Nav.Link>

            <NavDropdown className="ms-3 ms-lg-0 mb-lg-0 fw-bold me-lg-3" title='About'>
              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/History">History</Nav.Link> </NavDropdown.Item>
              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/Committee">Committee</Nav.Link></NavDropdown.Item>
            </NavDropdown>

            <NavDropdown className="ms-3 ms-lg-0 mb-lg-0 fw-bold me-lg-3" title='Advisor'>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/VP">Ashem Satyajit Singh</Nav.Link> </NavDropdown.Item>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/GS">Shaymol Singha Shyambabu</Nav.Link></NavDropdown.Item>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/ST">Ahaibam Ranjit Singha</Nav.Link></NavDropdown.Item>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/ST2">K Biren Singha</Nav.Link></NavDropdown.Item>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/AP">L, Nandalal Singha</Nav.Link></NavDropdown.Item>

              <NavDropdown.Item><Nav.Link className="text-dark" as={Link} to="/AD">Y Chandrajit</Nav.Link></NavDropdown.Item>

            </NavDropdown>

            <Nav.Link className="link me-lg-4" as={Link} to="/Contact">
              Contact
            </Nav.Link>

            {admin && (
              <>
                <Nav.Link className="link me-lg-4" as={Link} to="/AddBanner">
                  Add Banner
                </Nav.Link>
              </>
            )}

            {user?.email ? (
              <>


                <Button
                  className="btn ms-3 ms-lg-0 my-lg-0 my-2 rounded-1"
                  style={{
                    backgroundColor: "#DC3545",
                    color: "white",
                    borderRadius: '0px',
                    padding: '7px'
                  }}
                  onClick={logout}
                  variant="light"
                >
                  Logout
                </Button>

              </>
            ) : (
              <Nav.Link
                as={Link}
                to="/Login"
                className="link my-lg-0 my-2 ms-2 rounded-1"
                style={{ backgroundColor: "#5363E6", color: "white", width: '23%' }}
                variant="light"
              >
                Log in
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default ResponsiveNav;
