import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="pt-4 pb-1 text-light row justify-content-center mx-0" style={{ backgroundColor: '#1B4C5C', marginTop: '2px' }}>

      <div className="col-lg-4 col-6 text-start">
        <h1 style={{ fontFamily: 'Lexend', fontWeight: 'bolder' }} className="mb-4">BAMCHAS</h1>
        <p style={{ color: 'lightgray', fontFamily: 'sherif' }}> Monilal Singha (GS), Mobile: 01705713810,  <br /> Biki Singha, Mobile: 01612098059 </p>
        <button className="btn btn-secondary px-4 rounded-1"><a style={{ textDecoration: 'none', color: 'white', fontFamily: 'sherif' }} href="mailto:bmchas.syl@gmail.com">Email Us</a></button>
      </div>

      <div className="col-lg-2 col-6 text-lg-start text-end">
        <h4 style={{ fontFamily: 'Amita' }} className="fw-bold mb-3 mt-1">Quick Links</h4>
        <p style={{ letterSpacing: '2px' }}>
          <Link className="ms-2 text-light text-decoration-none" to="/News">News</Link>
        </p>
        <p style={{ letterSpacing: '2px' }}>
          <Link className="ms-2 text-light text-decoration-none" to="/Contact">Contact</Link>
        </p>
        <p style={{ letterSpacing: '2px' }}>
          <Link className="ms-2 text-light text-decoration-none" to="/History">History</Link>
        </p>
        <p style={{ letterSpacing: '2px' }}>
          <Link className="ms-2 text-light text-decoration-none" to="/Committee">Committee</Link>
        </p>
      </div>
      <div className="col-lg-2">
        <h4 style={{ fontFamily: 'Amita' }} className="fw-bold mb-3 mt-1">Follow us</h4>
        <a target="blank" href="https://www.facebook.com/bamchas/"><img className="w-50 mt-2" src="img/fb.png" alt="" /></a>
      </div>
      <div className="col-lg-3">
        <img className="w-50 mt-2" src="img/logo.png" alt="" />
        <p style={{ fontFamily: 'monospace', marginTop: '50px' }}>
          Copyright © www.bamchas.org
        </p>
      </div>

    </div>
  );
};

export default Footer;
