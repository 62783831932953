import React, { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Loading from '../Loading';

const News = () => {
    const [allnews, setAllnews] = useState([]);
    
    const [loading, setLoading] = useState(true);

    const { admin } = useAuth()

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/News')
            .then((res) => res.json())
            .then((data) => { setAllnews(data); setLoading(false) })
    }, []);
    const handleDelete = (id) => {
        const proceed = window.confirm("Are you sure, you want to delete?");
        if (proceed) {
            fetch(`https://bamchas-server.glitch.me/News/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.deletedCount > 0) {
                        alert("deleted successfully");
                        const remaining = allnews.filter((re) => re._id !== id);
                        setAllnews(remaining);
                    }
                });
        }
    };
    return (
        <div className='Top pt-2 pb-5'>
            <h2 style={{ color: '#002147', fontFamily: 'Alata', fontWeight: 'bold', marginTop: '25px' }}>News & Events{
                admin && <Link to="/AddNews"><button type="" className='btn btn-success ms-2 px-2'>ADD +</button></Link>
            }</h2>

            <hr className='mb-5' style={{marginTop: '-2px'}} />
            
            <div className='row justify-content-between mx-1'>
                {!loading ?
                    allnews.map((news) => (
                        <Fade key={news._id} bottom>
                        <div className='row justify-content-center mb-5 col-lg-6 mx-0'>
                            <div className='col-lg-5'>
                                <img className='w-100 rounded-1' src={news?.img1} alt="" />
                            </div>
                            <div className='text-lg-start col-lg-7 pe-0 me-0'>
                                <p style={{fontWeight: 'bold', fontSize: '14px', marginBottom: "-10px"}} className='text-secondary'>Published: {news?.date}</p>
                                <p style={{ fontFamily: 'monospace', fontSize: '18px'}} className='my-3 fw-bold'>{news?.title}</p>
                                <Link as={Link} to={`/NewsDetails/${news._id}`}>
                                    <button type="" className='btn btn-outline-primary py-1 px-2'>+ Read More</button>
                                </Link>
                                {
                                    admin && <button 
                                    className="btn btn-outline-danger ms-2  py-1 px-3"
                                    onClick={() => handleDelete(news._id)}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                                }
                                
                            </div>
                        </div>
                        </Fade>
                    )) : <Loading></Loading>
                }
            </div>
        </div>
    );
};

export default News;