import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import "./Modal.css"

const GModal = ({ openModal, handleModalClose, photo }) => {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
                
            <Fade in={openModal}>
                <Box className='Gmodal'>
                    <img className='w-100' src={photo.img} alt=""/>
                </Box>
            </Fade>
        </Modal>
    );
};

export default GModal;