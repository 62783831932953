import React from 'react';

const ST2 = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/ST2.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>K Biren Singha</h4>
                <p> Adviser of BAMCHAS <br /> Senior Teacher, Babnia Govt. Primary School, Kulaura</p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{ fontFamily: 'Lexend' }} className='ms-1'>Welcome to our newly redesigned website!  Knowing that you depend on our research and insight to develop new, innovative ideas and strengthen policy and practice, we’ve built our site to make it much easier to find and share resources. In this 31st anniversary year of our BAMCHAS founding, we continue to be inspired by the passion and dedication we see in social activities and educational development , program directors and policymakers and funders, all working on behalf of Manipuri students. As part of our commitment to keeping our work easily accessible, we have created a navigable website with additional resource and a visual design to support your work in the areas of family involvement, out-of-school time, early childhood education, complementary learning, education reform, and evaluation.
                </p>
            </div>
        </div>
    );
};

export default ST2;