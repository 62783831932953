import React from 'react';

const NotFound = () => {
    return (
        <div>
            <img className='w-75' src="https://cdn.dribbble.com/users/718859/screenshots/3267029/jisunpark_404-error.gif?fbclid=IwAR0JFUHX-Fr1mUm58fzN_lP7IOAFV8h0CW-7woYeg1nnRnBt9c8B0LIAl1Y" alt="" />
        </div>
    );
};

export default NotFound;