import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Components/Login/Login/Login";
import Register from "./Components/Login/Register/Register";
import AuthProvider from "./contexts/AuthProvider/AuthProvider";
import ResponsiveNav from "./Components/ResponsiveNav/ResponsiveNav";
import PrivateRoute from './Components/Login/PrivateRoute/PrivateRoute'
import News from "./Components/News/News";
import NewsDetails from "./Components/News/NewsDetails";
import Footer from "./Components/Footer/Footer";
import AddNews from "./Components/News/AddNews";
import Home from "./Components/Home/Home";
import AddBanner from "./Components/Header/AddBanner";
import History from "./Components/About/History";
import Committee from "./Components/About/Committee";
import Contact from "./Components/Contact/Contact";
import Photos from "./Components/Gallery/Photos";
import NotFound from "./Components/NotFound/NotFound"
import VP from "./Components/Advisor/VP";
import GS from "./Components/Advisor/GS";
import ST from "./Components/Advisor/ST";
import ST2 from "./Components/Advisor/ST2";
import AP from "./Components/Advisor/AP";
import AD from "./Components/Advisor/AD";

function App() {

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <ResponsiveNav></ResponsiveNav>
          <Switch>
            <Route exact path='/'>
              <Home></Home>
            </Route>
            <Route path='/Home'>
              <Home></Home>
            </Route>
            <Route path='/News'>
              <News></News>
            </Route>
            <Route path='/NewsDetails/:id'>
              <NewsDetails></NewsDetails>
            </Route>
            <PrivateRoute path='/AddNews'>
              <AddNews></AddNews>
            </PrivateRoute>
            <PrivateRoute path='/AddBanner'>
              <AddBanner></AddBanner>
            </PrivateRoute>
            <Route path="/History">
              <History></History>
            </Route>
            <Route path="/Committee">
              <Committee></Committee>
            </Route>
            <Route path="/Contact">
              <Contact></Contact>
            </Route>
            <Route path="/Photos">
              <Photos></Photos>
            </Route>
            <Route path="/Login">
              <Login></Login>
            </Route>
            <Route path="/Register">
              <Register></Register>
            </Route>
            <Route path="/VP">
              <VP></VP>
            </Route>
            <Route path="/GS">
              <GS></GS>
            </Route>
            <Route path="/ST">
              <ST></ST>
            </Route>
            <Route path="/ST2">
              <ST2></ST2>
            </Route>
            <Route path="/AP">
              <AP></AP>
            </Route>
            <Route path="/AD">
              <AD></AD>
            </Route>
            <Route path="*">
              <NotFound></NotFound>
            </Route>
          </Switch>
          <Footer></Footer>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
