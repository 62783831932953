/* eslint-disable array-callback-return */
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const AddBanner = () => {
    const { register, handleSubmit, reset } = useForm();

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/Banner')
            .then((res) => res.json())
            .then((data) => setBanners(data))
    }, []);
    

    const onSubmit = (data) => {
            axios.post("https://bamchas-server.glitch.me/Banner", data)
            .then((res) => {
              if (res.data.insertedId) {
                alert("Added SuccessFully");
                reset();
              }
            });
    }

    
    const handleDelete = (id) => {
        const proceed = window.confirm("Are you sure, you want to delete?");
        if (proceed) {
            fetch(`https://bamchas-server.glitch.me/Banner/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.deletedCount > 0) {
                        alert("deleted successfully");
                        const remaining = banners.filter((banner) => banner._id !== id);
                        setBanners(remaining);
                        window.location.reload();
                    }
                });
        }
    };


    return (
        <div style={{marginRight: '15px'}} className="my-5 px-1">
            <h3 style={{ color: "#002147", fontFamily: 'Alata' }} className="fw-bold">
                Add Banner
            </h3>
            <hr />
            <div className="mx-1 mb-5" style={{ paddingBottom: '50px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row text-start justify-content-center mx-0">
                        <div className="col-lg-5">
                            <label className="form-label">Image URL*</label>
                            <input
                                required
                                className="mb-4 form-control"
                                {...register("img")}
                                placeholder="Enter Image URL"
                              
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Choose Date *</label>
                            <input
                                required
                                type="date"
                                className="mb-4 form-control"
                                {...register("date")}
                                
                            />
                        </div>
                    </div>
                    <input
                        className="btn btn-outline-success"
                        value="Submit"
                        type="submit"
                    />
                </form>
            </div>
            <h3 style={{color: '#002147', fontFamily: 'Alata'}} className='fw-bolder'>All Banner</h3>
            <hr />
            <div className='row justify-content-center mx-0'>
            {banners.map((banner) => 
                <div key={banner._id} className='card col-lg-3 m-3 p-0'>
                    <img className='w-100' src={banner.img} alt=""/>
                    <p><i className="far fa-calendar-alt"></i> <span className='fw-bold'>{banner.date}</span>  
                       <button className="btn border-0 mb-1"
                                        onClick={() => handleDelete(banner._id)}
                                    ><i className="fas fa-trash text-danger"></i>
                                    </button> 
                        </p>
                </div>
            ) }
            </div>
            
        </div>
    );
};

export default AddBanner;