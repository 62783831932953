import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Zoom } from 'react-slideshow-image';
import "./bla.css"
import 'react-slideshow-image/dist/styles.css'
import Loading from '../Loading';


const Header = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/Banner')
            .then((res) => res.json())
            .then((data) => { setBanners(data); setLoading(true) })
    }, []);

    
    const properties = {
        prevArrow: <button className='arrow-btn1'><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m512 256c0 50.53125-15 99.675781-43.375 142.117188-6.136719 9.179687-18.558594 11.648437-27.742188 5.507812-9.183593-6.136719-11.648437-18.558594-5.511718-27.742188 23.964844-35.839843 36.628906-77.292968 36.628906-119.882812 0-119.101562-96.898438-216-216-216s-216 96.898438-216 216 96.898438 216 216 216c39.507812 0 78.144531-10.757812 111.738281-31.105469 9.445313-5.722656 21.742188-2.703125 27.464844 6.742188 5.726563 9.449219 2.707031 21.746093-6.742187 27.46875-39.84375 24.136719-85.648438 36.894531-132.460938 36.894531-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531zm-112 0c0-11.046875-8.953125-20-20-20h-207.183594l72.28125-71.8125c7.835938-7.785156 7.875-20.449219.089844-28.285156-7.785156-7.835938-20.449219-7.875-28.285156-.089844l-78.199219 77.695312c-11.417969 11.34375-17.703125 26.433594-17.703125 42.492188s6.285156 31.148438 17.703125 42.488281l78.199219 77.699219c3.902344 3.875 9 5.8125 14.097656 5.8125 5.140625 0 10.277344-1.96875 14.1875-5.902344 7.785156-7.835937 7.746094-20.5-.089844-28.285156l-72.28125-71.8125h207.183594c11.046875 0 20-8.953125 20-20zm0 0"/></svg></button>,
        nextArrow: <button className='arrow-btn2'><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m512 256c0 50.53125-15 99.675781-43.375 142.117188-6.136719 9.179687-18.558594 11.648437-27.742188 5.511718-9.183593-6.140625-11.648437-18.5625-5.511718-27.742187 23.964844-35.84375 36.628906-77.296875 36.628906-119.886719 0-119.101562-96.898438-216-216-216s-216 96.898438-216 216 96.898438 216 216 216c39.503906 0 78.144531-10.757812 111.738281-31.105469 9.445313-5.722656 21.746094-2.703125 27.46875 6.746094 5.722657 9.445313 2.703125 21.742187-6.746093 27.464844-39.84375 24.136719-85.648438 36.894531-132.460938 36.894531-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531zm-245.1875 120.097656c3.910156 3.933594 9.046875 5.902344 14.1875 5.902344 5.097656 0 10.195312-1.9375 14.097656-5.8125l78.199219-77.699219c11.417969-11.339843 17.703125-26.429687 17.703125-42.488281s-6.285156-31.148438-17.703125-42.488281l-78.199219-77.699219c-7.835937-7.785156-20.5-7.742188-28.285156.089844-7.785156 7.835937-7.746094 20.5.089844 28.285156l72.277344 71.8125h-207.179688c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h207.183594l-72.28125 71.8125c-7.835938 7.785156-7.875 20.449219-.089844 28.285156zm0 0"/></svg></button>
    }

    return (
        <div>
            <Zoom {...properties} indicators={true}>
                    { loading ?
                        banners.map((banner) => (
                        <div key={banner._id} className="each-slide-effect">
                            <img src={banner.img} className="d-block w-100 slide" alt="..." />
                        <div class="carousel-caption">
                            <h5 style={{marginBottom: '-30px', fontFamily: 'Alata', textShadow: '1px 1px black'}} className="text-light fw-bold">{banner?.date}</h5>
                        </div>
                        </div>
                        )) : <Loading></Loading>
                    }
            </Zoom>
        </div>
    );
};

export default Header;