const firebaseConfig = {
  apiKey: "AIzaSyD94W4fTkBjItH4tvAtbhaNE4mmpj2H1y8",

  authDomain: "mobile-99f0b.firebaseapp.com",

  projectId: "mobile-99f0b",

  storageBucket: "mobile-99f0b.appspot.com",

  messagingSenderId: "59622902621",

  appId: "1:59622902621:web:f673adbe78baf4f95ff6b4",

  measurementId: "G-58M3XYT9NV"

};
export default firebaseConfig;