import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';

const AddNews = () => {
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (data) => {
        axios.post("https://bamchas-server.glitch.me/News", data).then((res) => {
            if (res.data.insertedId) {
                alert("Added SuccessFully");
                reset();
            }
        });
    };
    return (
        <div className="my-5 mx-0">
            <h3 style={{ color: "#002147" }} className="fw-bold">
                Add a News
            </h3>
            <hr />
            <div style={{ paddingBottom: '100px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row text-start justify-content-center mx-0">
                        <div className="col-lg-5">
                            <label className="form-label">Image URL 1*</label>
                            <input
                                required
                                className="mb-4 form-control"
                                {...register("img1")}
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Image URL 2</label>
                            <input
                                className="mb-4 form-control"
                                {...register("img2")}
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Image URL 3</label>
                            <input
                                className="mb-4 form-control"
                                {...register("img3")}
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Image URL 4</label>
                            <input
                                className="mb-4 form-control"
                                {...register("img4")}
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Title</label>
                            <input
                                required
                                type="text"
                                className="mb-4 form-control"
                                {...register("title")}
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-lg-5">
                            <label className="form-label">Choose Date *</label>
                            <input
                                required
                                type="date"
                                className="mb-4 form-control"
                                {...register("date")}
                            />
                        </div>
                        <div className="col-lg-10">
                            <label className="form-label">Details *</label>
                            <textarea
                                required
                                type="text" rows="8"
                                className="mb-4 form-control"
                                placeholder="Details"
                                {...register("details")}
                            />
                        </div>
                    </div>
                    <input
                        className="btn btn-outline-success"
                        value="Submit"
                        type="submit"
                    />
                </form>
            </div>
        </div>
    );
};

export default AddNews;