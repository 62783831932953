import React from 'react';

const Contact = () => {
    return (
        <div>
            <h3 className='fw-bolder my-3' style={{color: '#002147'}}>Contact Us</h3>
            <hr />
            <div className='row justify-content-around mb-5 mt-3 align-items-center mx-1'>
                <div className='col-lg-5'>
                    <img className='w-100' src="img/contact.png" alt="" />
                </div>
                <div style={{fontFamily: 'Itim'}} className='col-lg-5 text-lg-start px-3 mt-4'>
                
                <p><i className="fas fa-map-marker-alt"></i> Manipuri Para, Subid bazer, Sylhet</p>
                <p> <i className="fas fa-phone-alt"></i> Monilal Singha (GS) - 01705713810, Biki Singha - 01612098059</p>
                <p> <i className="fas fa-envelope"></i> bmchas.syl@gmail.com</p>
            
                </div>
            </div>
            
        </div>
    );
};

export default Contact;