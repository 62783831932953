import React from 'react';

const Vp = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/VP.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>Ashem Satyajit Singh</h4>
                <p>Founder Vice President of BAMCHAS <br /> Educator and Social Worker <br /> Jackson Heights, U.S.A</p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{fontFamily: 'Lexend'}} className='ms-1'>”Ignorance is the curse of God and Education is a wing through which one can fly to heaven ”– says George Bernard Shaw. Educational backwardness, more particularly amongst the minorities, is a major issue of debate, discussion and accusation. Instead of blaming others and pointing out lapses on the part of other organization in the words of Rabindranath Tagore,—“It is better to light a candle than to curse the darkness.” Bangladesh Manipuri Chhatra Samity, (BAMCHHAS) is a key to change of educational era to our Manipuri nation. We had a dream in 1984. We built the home and opened a door for every student in Manipuri nation. Comparing to the other nationality we had observe that, we are far legged behind. We faced a lot of obstacles to bring our educational progress in our society. We dreamed of seeing an educated and strong society. How would that be? We created many educational programs to raise awareness among our Manipuri students. And we tried to uphold the glorious past of our society by enlightening the minds of our Manipuri students. Let’s start a new era together joining to the events going to be held by inaugurating the website of BAMCHAS on January 01, 2016. Thanks
                </p>
            </div>
        </div>
    );
};

export default Vp;