import React from 'react';

const AP = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/AP.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>L, Nandalal Singha</h4>
                <p>Adviser of BAMCHAS <br />
                    Associate Professor B,M University College (Ex) <br />
                    Chairman of Narmada Memorial Educational Trust </p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{ fontFamily: 'Lexend' }} className='ms-1'>
                Greetings and welcome to BAMCHAS, the home of the Manipuri Nation!  I am thrilled that you decided to view our BAMCHAS. Bangladesh Manipuri Chatra Samity (BAMCHAS) has established a tradition of excellence and we will continue to strive to become the best for Manipuri students. We are about to embark upon a new student with great enthusiasm and anticipation of new initiatives that will enhance our educational program and Manipuri community as a whole.As you enter our website, you will notice that all of our executive committee member have created and maintain a webpage to keep students abreast of news relevant to educational purpose. We are committed to providing innovative lessons to our students, incorporating technology in order to meet the needs of the 21st century learner.BAMCHAS is dedicated to providing our Manipuri students with a well-rounded educational experience.  While the core curriculum is provided for all students, we believe that it is critical to provide leadership opportunities, character education and exposure to the technologies in order to prepare students to compete with their global counterparts.We encourage you to visit our website and see that we are committed to Manipuri students for educational development.  You will find that this is a vibrant and engaging community of students and guardian. To our parents, we believe that your involvement is an essential component to the whole success of our community. I encourage you to visit our website often, meet our educational event and to get engaged in all that BAMCHAS has to offer. Your participation in our Samity is needed and greatly appreciated! I am very much pleased to see your unity in the common pursuit of success for our Manipuri students.
                </p>
            </div>
        </div>
    );
};

export default AP;