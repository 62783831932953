import React, { useEffect, useState } from 'react';
import { Zoom } from 'react-reveal';
import useAuth from '../../hooks/useAuth';
import GModal from './GModal';

const GG = ({ photo }) => {
    const [photos, setPhotos] = useState([])
    const { admin } = useAuth();

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/Photos')
            .then((res) => res.json())
            .then((data) => setPhotos(data))
    }, []);

    const [openModal, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);


    const handleDelete = (id) => {
        const proceed = window.confirm("Are you sure, you want to delete?");
        if (proceed) {
            fetch(`https://bamchas-server.glitch.me/Photos/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.deletedCount > 0) {
                        alert("deleted successfully");
                        const remaining = photos.filter((photo) => photo._id !== id);
                        setPhotos(remaining);
                        window.location.reload();
                    }
                });
        }
    };

    return (
        <Zoom>
            <div className='col-lg-2 col-md-3 col-sm-3 col-6 px-0'>
                <button onClick={handleModalOpen} className="border-0 col-lg-12">
                    <img className='w-100' src={photo.img} alt="" />
                </button>
                <p style={{ marginTop: '-35px', color: 'white' }}><i className="far fa-calendar-alt"></i> <span className='fw-bold'>{photo.date}</span>
                        {admin && <button className="btn border-0 mb-1"
                            onClick={() => handleDelete(photo._id)}
                        ><i className="fas fa-trash text-danger"></i>
                        </button>}
                    </p>

                <GModal
                    photo={photo}
                    openModal={openModal}
                    handleModalClose={handleModalClose}
                ></GModal>
            </div>


        </Zoom>
    );
};

export default GG;