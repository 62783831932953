import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Fade from 'react-reveal/Fade';
import Loading from "../Loading"
import "./Home.css"

const Home = () => {
    const [allnews, setAllnews] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/News')
            .then((res) => res.json())
            .then((data) => { setAllnews(data); setLoading(false) })

    }, []);
    return (
        <div className='Top'>

                    <Header></Header>

                    <h2 style={{ color: '#002147', fontFamily: 'Alata', fontWeight: 'bold', marginTop: '30px' }}>Latest News</h2>
                    <hr style={{marginTop: '-4px'}} />
                    <div className='row justify-content-center gap-4 mt-lg-4 mx-1'>
                        { !loading ?
                            allnews.slice(Math.max(allnews.length - 3, 0)).map((news) => (
                                <Fade key={news._id}>
                                    <div className='col-lg-3 col-md-5' style={{ boxShadow: '3px 3px 10px gray', backgroundColor: 'white' }}>
                                        <img style={{ height: '250px', width: '100%', paddingTop: '10px' }} src={news?.img1} alt="" />
                                        <div>
                                            <p className='text-secondary mt-1'> <i className="far fa-calendar-alt me-1"></i> {news?.date}</p>
                                            <p style={{ fontFamily: 'Alata', fontSize: '17px' }} className='my-3 fw-bold'>{news?.title}</p>
                                            <Link as={Link} to={`/NewsDetails/${news._id}`}>
                                                <button style={{ borderRadius: '25px' }} type="" className='btn btn-outline-primary mb-3 w-50'>+ Read More</button>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade> 
                            )) : <Loading></Loading>
                        }
                    </div>
                    <Link to="/News">
                        <button style={{ backgroundColor: '#0094B7' }} className='btn mt-4 text-light'>VIEW ALL <i className="far fa-hand-point-right ms-1"></i></button>
                    </Link>
                    <div style={{ fontFamily: 'monospace', marginBottom: '-47px' }} className='gg row py-3 mt-5 mx-0 justify-content-around'>
                        <div className='col-lg-12'>
                            <h1 className='fw-bolder text-light'>ACTIVITES</h1>
                            <p className='mb-5'>Bangladesh Manipuri ChatraSamity</p>
                        </div>

                        <div className='col-lg-2 rounded-circle bg-light col-5 mb-5'>
                            <img className='w-50' src="img/education.png" alt="" />
                            <h5 className='fw-bold mt-3'>EDUCATION</h5>
                        </div>

                        <div className='col-lg-2 rounded-circle bg-light col-5 mb-5'>
                            <img className='w-50' src="img/unity.png" alt="" />
                            <h5 className='fw-bold mt-3'>UNITY</h5>
                        </div>

                        <div className='col-lg-2 rounded-circle bg-light col-5 mb-5'>
                            <img className='w-50' src="img/rising.png" alt="" />
                            <h5 className='fw-bold mt-3'>PROGRESS</h5>
                        </div>

                        <div className='col-lg-2 rounded-circle bg-light col-5 mb-5'>
                            <img className='w-50' src="img/discipline.png" alt="" />
                            <h5 className='fw-bold mt-3'>DISCIPLINE</h5>
                        </div>

                    </div>
                    <div className='Bottom mt-5 row justify-content-center align-items-center mx-0 pt-5 pt-lg-0'>
                        <div className='col-lg-6'>
                            <p style={{ fontFamily: 'monospace' }} className='fs-1 fw-bold'>MEMBERSHIP FORM OF BAMCHAS</p>
                            <a href="img/MEMBERSHIP-FORM-BAMCHAS.docx" rel="noreferrer"> <button className='btn btn-warning fw-bold fs-5 rounded-5 px-4 pb-2'> Download</button> </a>
                        </div>
                        <div className='col-lg-5'>
                            <img className='w-100' src="img/vactor.png" alt="" />
                        </div>

                    </div>
        </div>
    );
};

export default Home;
