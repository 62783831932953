import React from 'react';

const GS = () => {
    return (
        <div className='row justify-content-center my-5 mx-0'>
            <div className='col-lg-2 col-6'>
                <img className='w-100' src="img/GS.jpg" alt="" />
            </div>
            <div className='col-lg-8 text-lg-end mt-3'>
                <h4 style={{ fontFamily: 'monospace' }} className='fw-bolder'>N Shaymol Singha Shyambabu</h4>
                <p>Founder General Secretary of BAMCHAS <br /> Social Activist </p>
            </div>
            <div className='col-lg-11 text-lg-start mt-4'>
                <h4 className='text-center fw-bold text-uppercase'>Massege</h4>
                <hr style={{ marginTop: '-2px' }} />
                <p style={{ fontFamily: 'Lexend' }} className='ms-1'>
                    Welcome to BAMCHAS! The start of a new website is an exciting time, and I look forward to welcoming you to the Manipuri Students and community.

                    It has been a busy year and you should expect some changes around Manipuri Students Society, Students Affairs continues to reorganize and re-envision programs to maintain quality services for students. I encourage you to explore all that BAMCHAS has to offer. You can find out about social and educational activities such as recreation and athletics, student organizations, resource centers, university interfaith council, environmental groups, , and more..

                    Our mission in Student Affairs is simple-to support you and create a living-learning community where you thrive, belong, and are engaged world citizens. Clearly you are the heart of the Manipuri community and an integral part of our Meitei Nation. We are always available to help you. If you have questions, concerns, or need assistance, just ask. Log on to www.bamchas.org for more information and to schedule an appointment.

                    Again, I welcome you to Bangladesh Manipuri Chatra Samity and look forward to meeting you. I hope for the betterment and development of the society following your innovative ideas and directions.
                </p>
            </div>
        </div>
    );
};

export default GS;