import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import Loading from '../Loading';
import AddPhotos from './AddPhotos';
import GG from './GG';

const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);

    const { admin } = useAuth();

    useEffect(() => {
        fetch('https://bamchas-server.glitch.me/Photos')
            .then((res) => res.json())
            .then((data) => { setPhotos(data); setLoading(false) })
    }, []);



    return (
        <div className='AlbumBg pt-2 pb-5'>

            {admin && <AddPhotos></AddPhotos>}

            <h3 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'monospace', marginTop: '30px' }}>Photo gallery</h3>
            <hr />
            
            <div className='row justify-content-lg-around mx-0'>
                {!loading ? photos.map((photo) =>
                    <GG key={photo._id} photo={photo}></GG>
                ) : <Loading></Loading>}
            </div>

        </div>
    );
};

export default Photos;